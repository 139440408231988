<template lang="pug">
  div
    .top
      h1(v-translate) Transactions
      Widgets(:stats="statistics")

    .transactions(v-if="data && data.length > 0")
      Trx(
        v-for="(trx, i) in data"
        :key="i"
        :trx="trx"
      )
    .loader(v-else)

    Pagination(
      v-if="data && data.length > 0"
      @change="pagination"
      :perPage="params.limit"
      :total="stats.summary ? (stats.summary - 1) : 0"
    )

</template>

<script>
import Pagination from '@/components/common/Pagination'
import Trx from '@/components/explorer/Trx'
import { mapGetters } from 'vuex'
import api from '@/api/explorer'

export default {
  name: 'TransactionsTab',
  components: { Pagination, Trx },
  computed: {
    ...mapGetters(['currency']),
    statistics () {
      return [
        {
          label: this.$translate.text('Total transactions'),
          number: this.stats.summary || 0,
          customClass: 'is-12-mobile is-4-tablet is-4-desktop'
        },
        {
          label: this.$translate.text('Transactions per hour (average)'),
          number: this.stats.per_hour || 0,
          customClass: 'is-12-mobile is-4-tablet is-4-desktop'
        },
        {
          label: this.$translate.text('24 hours transaction'),
          number: this.stats.per_day || 0,
          customClass: 'is-12-mobile is-4-tablet is-4-desktop'
        }
      ]
    }
  },
  data () {
    return {
      loading: false,
      data: [],
      stats: {},
      params: {
        limit: 20,
        offset: 0
      },
      trxInterval: null
    }
  },
  methods: {
    pagination (params) {
      if (params && params.offset >= 0 && params.limit > 0) {
        this.params = params
        this.fetchTrxs()
        clearInterval(this.trxInterval)
      } else {
        this.updateTrxs()
      }
    },
    updateTrxs () {
      this.trxInterval = setInterval(() => {
        this.fetchTrxs()
      }, 10000)
    },
    fetchTrxs () {
      this.loading = true
      console.log(this.params)
      api
        .getTransactions(this.params)
        .then(({ data }) => {
          this.data = data.data
          this.loading = false
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
        })
    },
    updateStats () {
      this.statsInterval = setInterval(() => {
        this.fetchStats()
      }, 10000)
    },
    fetchStats () {
      api.getTransactionsStats()
        .then(({ data }) => {
          this.stats = data.data
        })
    },
    fetchData () {
      this.fetchTrxs()
      this.fetchStats()
      this.updateTrxs()
      this.updateStats()
    }
  },
  beforeMount () {
    this.fetchData()
  },
  beforeDestroy: function () {
    clearInterval(this.trxInterval)
    clearInterval(this.statsInterval)
  }
}
</script>

<style scoped>
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #544323 0%, #262B35 100%);
}
.transactions {
  margin-bottom: 1.5rem;
}
</style>
