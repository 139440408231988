<template lang="pug">
  div
    .top
      h1(v-translate) Price
    Widgets(:stats="priceStats")
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api/explorer'

export default {
  name: 'PriceTab',
  data () {
    return {
      stats: {
        volume_all: 0,
        volume_p2p: 0,
        volume_exchange: 0,
        prices: {
          high24hr: [],
          low24hr: []
        },
        liquid: {
          bids: 0,
          asks: 0
        }
      },
      prices: {
        high24hr: [],
        low24hr: []
      },
      interval: null
    }
  },
  computed: {
    ...mapGetters(['currency', 'courses']),
    priceStats () {
      return [
        {
          label: this.$translate.text('Price'),
          numbers: [
            {
              value: '1 UMI = '
            },
            {
              number: 100,
              convertToFiat: true
            }
          ],
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          label: this.$translate.text('Trading volume on exchanges in 24 hours'),
          labelLink: this.$website + 'volumes',
          numbers: [
            {
              number: this.stats.volume_all,
              suffix: 'UMI'
            },
            {
              prefix: ' / ',
              number: this.stats.volume_all,
              convertToFiat: true,
              suffix: this.currency
            }
          ],
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          label: this.$translate.text(
            'P2P trading volume on exchanges in 24 hours'
          ),
          labelLink: this.$website + 'volumes',
          numbers: [
            {
              number: this.stats.volume_p2p,
              suffix: 'UMI'
            },
            {
              prefix: ' / ',
              number: this.stats.volume_p2p,
              convertToFiat: true,
              suffix: this.currency
            }
          ],
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          label:
            this.$translate.text('Liquidity') +
            ' (' +
            this.$translate.text('buy') +
            '/' +
            this.$translate.text('sell') +
            ')',
          labelLink: this.$website + 'liquidity',
          numbers: [
            {
              number: this.stats.liquid.bids[this.currency] || 0,
              suffix: this.currency + ' / '
            },
            {
              number: this.stats.liquid.asks[this.currency] || 0,
              suffix: this.currency
            }
          ],
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          label: this.$translate.text('Maximum price for 24 hours'),
          numbers: this.stats.prices.high24hr || [],
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          label: this.$translate.text('Minimum price for 24 hours'),
          numbers: this.stats.prices.low24hr || [],
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        }
      ]
    }
  },
  methods: {
    fetch () {
      api.getLiquid()
        .then(({ data }) => {
          this.stats.liquid = data.data.totals.all
        })
      api.getVolumes()
        .then(({ data }) => {
          const allPairs = Object.assign(data.data.exchange, data.data.trades)
          this.stats.prices = {
            high24hr: [],
            low24hr: []
          }
          Object.keys(this.courses).map(item => {
            for (const [key, value] of Object.entries(allPairs)) {
              if (key.indexOf(item) > -1) {
                if (value.high24hr > 0) {
                  this.stats.prices.high24hr.push({
                    number: value.high24hr,
                    suffix: item,
                    prefix: ' / '
                  })
                }
                if (value.low24hr > 0) {
                  this.stats.prices.low24hr.push({
                    number: value.low24hr,
                    suffix: item,
                    prefix: ' / '
                  })
                }
              }
            }
            return true
          })

          delete this.stats.prices.high24hr[0].prefix
          delete this.stats.prices.low24hr[0].prefix

          this.stats.volume_all = parseFloat(data.data.totals.all * 100)
          this.stats.volume_p2p = parseFloat(data.data.summary.trades * 100)
          this.stats.volume_exchange = parseFloat(data.data.summary.exchange * 100)
        })
    }
  },
  created () {
    this.fetch()
    this.interval = setInterval(() => {
      this.fetch()
    }, 6000)
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.top {
  background-color: #262B35;
  /* background-image: url(../../../assets/tabs/prices_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: calc(100% - 8.25rem);
  height: 23rem; */
}
</style>
