<template lang="pug">
//- p {{ structure.prefix }}
.wrap
  .level.top
    .level-left
      span {{ structure.prefix.replace('rod', '') }}
      img(v-webp="require(`@/assets/rod.png`)")
      span {{ structure.description }}
    .level-right
      .percent {{ structure.interestRate / 100 }}%
      span( v-translate ) Staking percent
  .columns.is-flex.is-multiline
    .column.is-4-desktop.is-6-tablet.is-12-mobile( v-for='item in items' )
      .is-flex.title
        .dot
        router-link( v-if='item.href' :to='item.href' ) {{ item.title }}
        span( v-else ) {{ item.title }}
      p.content {{ item.content }}
</template>

<script>
import numeral from 'numeral'

export default {
  props: {
    structure: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    items () {
      return [
        // {
        //   title: (this.structure.profitPercent / 100) + '%',
        //   content: this.$translate.text('Fee')
        // },
        {
          title: numeral(this.structure.addressCount || 0).format('0,0'),
          content: this.$translate.text('Members')
        },
        {
          title: this.cut(this.structure.profitAddress || '-'),
          content: this.$translate.text('Profit Wallet'),
          href: this.structure.profitAddress ? '/address/' + this.structure.profitAddress : undefined
        },
        {
          title: this.toUmi(this.structure.balance || 0),
          content: this.$translate.text('Total coins')
        },
        // {
        //   title: (this.structure.depositPercent || 0) / 100,
        //   content: this.$translate.text('Structure Income')
        // }
        // {
        //   title: this.cut(this.structure.transitAddress || '-'),
        //   content: this.$translate.text('Transit Wallet'),
        //   href: this.structure.transitAddress ? '/address/' + this.structure.transitAddress : undefined
        // }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #3B434F;
  background: #1E232E;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.12);
  .top {
    border-radius: 4px;
    background: linear-gradient(-90deg, #262B35, #542323);
    width: calc(100% - 2rem);
    margin: -2rem auto 0!important;
    padding: 1.25rem 1rem!important;
    .level-left {
      font-family: Merriweather!important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.32px;
      img {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 .5rem;
      }
    }
    .level-right {
      .percent {
        border-radius: 4px 4px 0px 4px;
        // background: #19AB4F;
        background: #F51500;
        box-shadow: 0px 4px 4px 0px rgba(5, 51, 22, 0.40);
        padding: .25rem;
        margin-right: .5rem;
      }
      span {
        color: #BDC1D6;
        font-size: 14px;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.28px;
      }
    }
  }

  .columns {
    padding: 2.25rem 1.75rem;
    .title {
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: .5rem;
      margin-bottom: 1px;
      .dot {
        width: .5625rem;
        height: .5625rem;
        border: .1875rem solid #F51500;
        border-radius: 50%;
        background: #fff;
        margin-right: 1rem;
        margin-top: 8.82px;
      }
      span,
      a {
        color: #17A89B;
        font-family: PT Mono;
        font-size: 18px;
        font-weight: 400;
        line-height: 148%;
        letter-spacing: 0.36px;
      }
    }
    .content {
      padding-left: 2rem;
      color: #BDC1D6;
      font-size: 14px;
      font-weight: 300;
      line-height: 150%;
      letter-spacing: 0.28px;
    }
  }
}
</style>
