<template lang="pug">
  div
    .top
      h1(v-translate) Transaction
      Widgets(v-if="meta" :stats="meta")

    .transactions(v-if="trx")
      Trx(
        :trx="trx"
      )
    .loader(v-else)

</template>

<script>
import Pagination from '@/components/common/Pagination'
import Trx from '@/components/explorer/Trx'
import { mapGetters } from 'vuex'
import api from '@/api/explorer'

export default {
  name: 'Transaction',
  components: { Pagination, Trx },
  data () {
    return {
      trx: null
    }
  },
  mounted () {
    if (this.$route.params.transaction) {
      api.getTransaction(this.$route.params.transaction, this.$route.query.block ? this.$route.query.block : null).then(res => {
        this.trx = res.data.data
      })
    }
  },
  computed: {
    ...mapGetters(['currency']),
    meta () {
      const meta = []
      const excluded = ['name', 'description', 'author', 'contentType']

      if (!this.trx || !this.trx.nft || !this.trx.nftData.meta) return null
      if (this.trx.nftData.meta.name) {
        meta.push({
          label: this.$translate.text('Name'),
          value: this.trx.nftData.meta.name,
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        })
      }
      if (this.trx.nftData.meta.description) {
        meta.push({
          label: this.$translate.text('Description'),
          value: this.trx.nftData.meta.description,
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        })
      }
      if (this.trx.nftData.meta.author) {
        meta.push({
          label: this.$translate.text('Author'),
          value: this.trx.nftData.meta.author,
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        })
      }
      if (this.trx.nftData.meta.contentType) {
        meta.push({
          label: this.$translate.text('Content Type'),
          value: this.trx.nftData.meta.contentType,
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        })
      }
      Object.keys(this.trx.nftData.meta).map(key => {
        if (!excluded.includes(key)) {
          meta.push({
            label: key,
            value: JSON.stringify(this.trx.nftData.meta[key]),
            customClass: 'is-12-mobile is-6-tablet is-4-desktop'
          })
        }
        return true
      })
      return meta
    }
  }
}
</script>

<style scoped>
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #544323 0%, #262B35 100%);
}
.transactions {
  margin-bottom: 1.5rem;
}
</style>
