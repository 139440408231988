<template lang="pug">
div
  .top
    .container
      .columns.is-vcentered.is-flex-mobile.is-multiline
        .column.is-6-desktop
          img.logo(v-webp="require(`@/assets/full_logo.png`)")
        .column.is-6-desktop.is-narrow-mobile
          Speedometer(
            min="0"
            :max="stats.transactions.bandwidth"
            :current="stats.transactions.per_second"
          )
  .layout
    .item
      StatItem(
        v-for="(item, i) in stakeStats"
        :key="'stake' + i"
        :item="item"
      )
      StatItem(
        v-for="(item, i) in blocksStats"
        :key="'blocks' + i"
        :item="item"
      )
    .item
      StatItem(
        v-for="(item, i) in commonStats"
        :key="'common' + i"
        :item="item"
      )
      StatItem(
        v-for="(item, i) in transactionsStats"
        :key="'transactions' + i"
        :item="item"
      )
</template>

<script>
// import DashedLine from '@/components/common/DashedLine'
import StatItem from "@/components/common/StatItem"
import Speedometer from '@/components/common/Speedometer'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Status',
  components: { StatItem, Speedometer },
  data () {
    return {
      test: 0,
      interval: null
    }
  },
  mounted () {
    this.$store.dispatch('getStats')
    this.interval = setInterval(() => {
      this.$store.dispatch('getStats')
    }, 6 * 1000)
  },
  computed: {
    ...mapGetters(['stats', 'currency']),
    ...mapState({
      rates: state => state.umiRates
    }),
    stakeStats () {
      return [
        {
          value: this.toUmi(this.stats.status.coins, 0),
          label: this.$translate.text('Total coins')
        },
        {
          value: this.toUmi(this.stats.status.emission, 0),
          label: this.$translate.text('Initial Emission')
        },
        {
          value: this.toUmi(this.stats.status.staking, 0),
          label: this.$translate.text('In structures')
        },
        {
          value: this.convert(this.stats.transactions.summary) || 0,
          label: this.$translate.text('Transactions')
        },
        {
          value: this.convert(this.stats.blocks.summary) || 0,
          label: this.$translate.text('Blocks')
        }
      ]
    },
    blocksStats () {
      return [
        {
          value: parseInt(this.stats.blocks.seconds_between_blocks),
          label: this.$translate.text('Block time'),
          suffix: ' ' + this.$translate.text('sec')
        },
        {
          value: parseInt(this.stats.blocks.transactions_in_last_block),
          label: this.$translate.text('Transactions in block')
        },
        {
          value: parseInt(this.stats.transactions.per_min),
          label: this.$translate.text('Transactions per min')
        }
      ]
    },
    transactionsStats () {
      return [
        {
          value: this.convert(this.stats.transactions.per_hour),
          label: this.$translate.text('Transactions per hour')
        },
        {
          value: this.convert(this.stats.transactions.per_day),
          label: this.$translate.text('Daily transactions')
        }
      ]
    },
    commonStats () {
      return [
        {
          label:
            this.$translate.text('Trading volume,\nCommon') + '/ ' + this.currency,
          link: this.website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_all, 0) + ' UMI</span> \n/ ' + this.toUmi(this.stats.volume_all * this.rates[this.currency], this.currency === "BTC" ? 4 : 0) + " " + this.currency
        },
        {
          label:
            this.$translate.text('Trading volume,\nP2P'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_p2p, 0) + ' UMI</span> \n/ ' + this.toUmi(this.stats.volume_p2p * this.rates[this.currency], this.currency === "BTC" ? 4 : 0) + " " + this.currency
        },
        {
          label:
            this.$translate.text('Trading volume,\nExchange'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_exchange, 0) + ' UMI</span> \n/ ' + this.toUmi(this.stats.volume_exchange * this.rates[this.currency], this.currency === "BTC" ? 4 : 0) + " " + this.currency
        },
        {
          label: this.$translate.text('Liquidity,\nCommon') + ' ' + this.currency + ' (' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.website + 'liquidity',
          value: (this.stats.liquid ? this.toUmi(this.stats.liquid.bids[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.liquid ? this.toUmi(this.stats.liquid.asks[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Liquidity, P2P') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.p2p_liquid ? this.toUmi(this.stats.p2p_liquid.bids[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.p2p_liquid ? this.toUmi(this.stats.p2p_liquid.asks[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Liquidity, Exchange') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.exchange_liquid ? this.toUmi(this.stats.exchange_liquid.bids[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.exchange_liquid ? this.toUmi(this.stats.exchange_liquid.asks[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Capitalization'),
          // value: this.pretty(this.stats.status.coins, true, true),
          value: this.toUmi(this.stats.status.coins * this.rates[this.currency], this.currency === "BTC" ? 6 : 0) + " " + this.currency
        }
      ]
    }
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
.top {
  background: radial-gradient(
    86.57% 1033.5% at 96.99% 13.43%,
    #235431 0%,
    #262b35 100%
  );
  display: flex;
  align-items: center;
  .logo {
    max-height: 4rem;
    max-width: 9rem;
    width: 100%
  }
}
.layout {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  row-gap: 2rem;
  // @media screen and (max-width: 1024px) {
  //   grid-template-columns: repeat(2, minmax(0, 1fr));
  // }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
