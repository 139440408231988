<template lang="pug">
div
  .top
    h1(v-translate) Addresses
    Widgets(:stats="addressesStats")
  .b-table.table-container(v-if="stats.top && stats.top.length > 0")
    table.table
      thead
        tr
          th.rang.is-hidden-mobile №
          th(v-translate) Address
          th {{ t('Balance') }} (UMI)
      tbody
        tr(v-for="(item, index) in stats.top")
          td.rang.is-hidden-mobile {{ (index+1) }}
          td.address
            router-link.address(:to="{ name: 'Address', params: { address: item.address, lang: prefix }}") {{ cut(item.address, 8) }}
          td.amount {{ toUmi(item.value) }}
  .loader(v-else-if="loading")

</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api/explorer'

export default {
  name: 'AddressesTab',
  data () {
    return {
      loading: false,
      stats: {
        top: null,
        top_value: 0,
        active: 0,
        loading: false
      },
      columns: [
        {
          field: this.$translate.text('Address'),
          label: 'address'
        },
        {
          field: this.$translate.text('Balance') + ' (UMI)',
          label: 'value'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['course', 'currency']),
    prefix () {
      return this.$translate.current !== 'en' ? this.$translate.current : null
    },
    genesis () {
      return (location.host.indexOf('testnet') > -1 || location.host.indexOf('localhost') > -1 || location.host.indexOf('heroku') > -1)
        ? 'umi1tjsevd884gzt78cn26yjmke3tyer4e0tfxl74yq8unrd9s0wts4q5w7xrc'
        : 'umi1funq869de06x8tdfth0hs5z8v37ral3yyalmuvj7j0z94rtxcaxq289cf9'
    },
    addressesStats () {
      return [
        {
          label: this.$translate.text('Genesis address'),
          linkTo: '/address/' + this.genesis,
          linkTitle: this.genesis,
          customClass: 'is-12-mobile is-12-tablet is-6-desktop'
        },
        // {
        //   label: this.$translate.text('Top 100 richest addresses'),
        //   numbers: [
        //     {
        //       number: this.stats.top_value || 0,
        //       suffix: 'UMI'
        //     },
        //     {
        //       prefix: ' / ',
        //       number: this.stats.top_value || 0,
        //       convertToFiat: true
        //     }
        //     // {
        //     //   prefix: ' / ',
        //     //   number:
        //     //     parseFloat((parseInt(this.stats.top_value) / (this.stats.status.coins / 100)).toFixed(2)),
        //     //   suffix: '%'
        //     // }
        //   ],
        //   customClass: 'is-6-mobile is-6-tablet is-6-desktop'
        // },
        {
          number: this.stats.active_addresses || 0,
          label: this.$translate.text('Active addresses in 24 hours'),
          customClass: 'is-6-mobile is-6-tablet is-3-desktop'
        },
        {
          label: this.$translate.text('Number of UMI addresses'),
          number: this.stats.umi_wallets || 0,
          customClass: 'is-6-mobile is-6-tablet is-6-desktop'
        },
        {
          label: this.$translate.text('Number of addresses in structures'),
          number: this.stats.deposit_wallets || 0,
          customClass: 'is-6-mobile is-6-tablet is-6-desktop'
        }
      ]
    }
  },
  methods: {
    fetch () {
      this.loading = true
      api.getAddressStats()
        .then(({ data }) => {
          this.stats = data.data
          this.loading = false
        })
    }
  },
  created () {
    this.fetch()
    this.interval = setInterval(() => {
      this.fetch()
    }, 10000)
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #442354 0%, #262B35 100%);
}
.address a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
</style>
