<template lang="pug">
div
  .top(:ref="counter")
    h1(v-if="address") {{ t('UMI address').replace('UMI', prefix) }}
    h1(v-else-if="!loading" v-translate) Address not exist
    .columns.is-vcentered
      .column
        .card
          .card-content
            .media
              .media-content
                .label(v-translate) Balance
                .count.balance(v-if="balance !== null")
                  | {{ toUmi(balance * 100) }}
                  SvgIcon.sign(v-if="prefix === 'UMI'" name="sign")
                  span.sign(v-else) {{ ' ' + prefix?.substring(0, 3).toUpperCase() }}
                .loader(v-else)
      .column
        .card
          .card-content
            .media
              .media-content
                .label(v-translate) Address
                .count.address-field
                  .address {{ address }}
                  .action(v-clipboard:copy="address" v-clipboard:success="onCopy")
                    SvgIcon.copy(name="content-copy")

    Widgets(:stats="stats")

  .container(v-if="trxStats.all > 0")
    .level
      .level-left
        .has-text-centered-mobile {{ filter === 'nfts' ? t('NFT collection') : t('Transactions') }}
      .level-right
        .filters.columns.is-marginless.is-multiline.is-flex-mobile
          .column.is-narrow-tablet(
            :class="{ 'is-active': filter === 'all' }"
            @click="changeFilter('all')"
          ) {{ t('All') }} ({{ trxStats.all }})
          .column.is-narrow-tablet(
            v-if="trxStats.received > 0"
            :class="{ 'is-active': filter === 'received' }"
            @click="changeFilter('received')"
          ) {{ t('Received') }} {{ `(${trxStats.received})` }}
          .column.is-narrow-tablet(
            v-if="trxStats.sended > 0"
            :class="{ 'is-active': filter === 'sended' }"
            @click="changeFilter('sended')"
          ) {{ t('Sent') }} {{ `(${trxStats.sended})` }}
          .column.is-narrow-tablet(
            v-if="wallet.nftCount > 0"
            :class="{ 'is-active': filter === 'nfts' }"
            @click="changeFilter('nfts')"
          ) {{ t('NFT') }} {{ `(${wallet.nftCount})` }}

    .columns.is-multiline.nfts(v-if="wallet.nftCount > 0 && filter === 'nfts'")
      .column.is-12-mobile.is-4-tablet.is-4-desktop.is-3-widescreen(v-for="(nft, i) in wallet.nfts")
        .nft(v-if="nft")
          a(target="_blank" :href="$blockchain + 'transaction/' + nft.trx")
            img(v-show="isLoaded.includes(i)" @load="onImgLoad(i)" @error="onError(i)" :src="nft.url" :alt="nft.url")
            .loader(v-if="!isError.includes(i) && !isLoaded.includes(i)")
            .noImage(v-if="isError.includes(i) && !isLoaded.includes(i)")
              span(v-if="!nft.meta") {{ t("No meta data") }}
              span(v-if="nft.meta && !isLoaded.includes(i)") {{ (nft.meta.contentType && nft.meta.contentType.startsWith('image')) ? t("Preview did't load") : t("Custom NFT content") }}
              br
              span.hash(v-if="!isLoaded.includes(i)") {{ cut(nft.trx) }}
            ul.meta
              li(v-if="nft.meta" v-for="(value, key) in nft.meta")
                span.key {{ key }}:
                span.value {{ value }}

    div(v-else-if="!loading")
      .transactions(v-if="transactions && transactions.length > 0")
        TrxSingle(
          v-for="(trx, i) in transactions"
          :key="i"
          :trx="trx"
          :address="address"
        )

      Pagination(
        v-if="trxStats[filter] > params.limit"
        @change="pagination"
        :perPage="params.limit"
        :total="total"
      )
    .loader(v-else-if="loading")

</template>

<script>
import Trx from '@/components/explorer/Trx'
import TrxSingle from '@/components/explorer/TrxSingle'
import GrowingNumber from '@/components/common/GrowingNumber'
import Pagination from '@/components/common/Pagination'
import api from '@/api/explorer'
import { mapGetters } from 'vuex'
import { Address } from '@/umi-top/umi-core-js'
// const fetch = require('node-fetch')

export default {
  name: 'Address',
  components: {
    Trx,
    TrxSingle,
    GrowingNumber,
    Pagination
  },
  data () {
    return {
      loading: false,
      counter: 0,
      wallet: {
        balance: 0,
        received: {
          count: 0,
          value: 0
        },
        sent: {
          count: 0,
          value: 0
        },
        count: 0,
        nfrCount: 0,
        nfts: []
      },
      balance: '',
      simulate: '',
      transactions: [],
      allTransactions: null,
      params: {
        limit: 25,
        offset: 0
      },
      filter: 'all',
      interval: null,
      timer: null,
      modalActive: false,
      isLoaded: [],
      isError: []
    }
  },
  computed: {
    ...mapGetters(['currency', 'tokens']),
    address () {
      return this.$route.params.address.trim().toLowerCase()
    },
    prefix () {
      // return this.address.substr(0, 3).toUpperCase().replace('GLS', 'GLZ')
      return Address.fromBech32(this.address).getPrefix()
    },
    sign () {
      return this.prefix
    },
    total () {
      if (this.filter === 'sended') {
        return parseInt(this.wallet.sent.count)
      }
      if (this.filter === 'received') {
        return parseInt(this.wallet.received.count)
      }
      return this.wallet.count
    },
    trxStats () {
      return {
        all: this.wallet.count,
        sended: this.wallet.sent.count,
        received: this.wallet.received.count
      }
    },
    stats () {
      if (this.balance === null || !this.tokens[this.prefix]) return null
      const stats = []
      let price = 1
      try {
        price = this.balance / this.tokens[this.prefix].value
      } catch (e) {
        price = 1
      }
      if (this.tokens[this.prefix].value) {
        stats.push({
          label: 'В валюте',
          value: '≈ ' + this.pretty((price || 0) * 100),
          customClass: 'is-6 is-12-mobile'
        })
      }
      if ((this.wallet.sent && this.wallet.sent.value > 0) || (this.wallet.received && this.wallet.received.value > 0)) {
        stats.push({
          label: this.$translate.text('Total sent'),
          numbers: [{
            number: this.wallet.sent.value,
            suffix: this.prefix,
            lined: true
          },
          {
            value: this.tokens[this.prefix].value
              ? ('≈ ' + this.pretty(this.wallet.sent.value / this.tokens[this.prefix].value))
              : '',
            lined: true
          }]
        })
        stats.push({
          label: this.$translate.text('Total received'),
          numbers: [{
            number: this.wallet.received.value,
            suffix: this.prefix,
            lined: true
          },
          {
            value: this.tokens[this.prefix].value
              ? ('≈ ' + this.pretty(this.wallet.received.value / this.tokens[this.prefix].value))
              : '',
            lined: true
          }]
        })
      }
      return stats
    }
  },
  methods: {
    onImgLoad (id) {
      this.isLoaded.push(id)
    },
    onError (id) {
      this.isError.push(id)
    },
    toggleModal () {
      this.modalActive = !this.modalActive
    },
    changeFilter (type = null) {
      if (this.filter !== type) {
        this.loading = true
        this.filter = type
        this.page = 1
        this.params.offset = 0
        if (this.$route.query.page) {
          this.$router.push({ query: { page: 1 } }).catch(e => {})
        }
        if (this.filter === 'nfts') {
          this.$router.push({ query: { filter: 'nfts' } }).catch(e => {})
          this.transactions = this.wallet.nfts
          clearInterval(this.interval)
        } else if (this.allTransactions && this.allTransactions.length === this.wallet.count) {
          this.transactions = this.allTransactions.filter(trx => this.filter === 'sended'
            ? trx.sender === this.wallet.address
            : this.filter === 'received'
              ? trx.sender !== this.wallet.address
              : trx
          )
          this.loading = false
        } else {
          this.fetchTransactions()
        }
      }
    },
    pagination (params) {
      if (params.offset > 0) {
        clearInterval(this.interval)
        this.interval = null
      } else if (!this.interval) {
        this.interval = setInterval(() => {
          this.fetchTransactions()
        }, 5000)
      }
      if (params.offset !== this.params.offset) {
        this.params = params
        this.fetchTransactions()
      }
      this.params = params
    },
    fetchTransactions () {
      Address.fromBech32(this.address)
      if (this.address) {
        if (this.allTransactions && this.allTransactions.length === this.wallet.count) {
          this.transactions = this.allTransactions.filter(trx => this.filter === 'sended'
            ? trx.sender === this.wallet.address
            : this.filter === 'received'
              ? trx.sender !== this.wallet.address
              : trx
          )
          this.transactions = this.transactions.slice(this.params.offset, this.params.offset + this.params.limit)
        } else {
          api
            .getAddressTransactions(this.address, {
              limit: this.transactions.length > 0 ? this.params.limit : 500,
              offset: this.params.offset
            }, this.filter)
            .then(({ data }) => {
              if (this.transactions.length !== data.data.length) { this.fetchAddress() }
              if (this.filter === 'all') {
                this.allTransactions = data.data
              }
              this.transactions = data.data.slice(0, this.params.limit)
              this.transactions.filter(trx => trx.address)
              this.loading = false
            })
            .catch(() => {
              clearInterval(this.interval)
            })
        }
      }
    },
    fetchAddress () {
      Address.fromBech32(this.address)
      if (this.address) {
        api.getAddress(this.address)
          .then(res => {
            this.wallet = res.data.data
            this.update()
          })
          .catch((e) => {
            console.log(e)
            clearInterval(this.interval)
            this.$route.push('/')
          })
      }
    },
    update () {
      this.balance = this.wallet.balance.confirmed_value / 100
      this.simulate = ''
    },
    fetchData () {
      this.loading = true
      clearInterval(this.interval)

      this.fetchAddress()
      this.fetchTransactions()
      this.interval = setInterval(() => this.fetchTransactions(), 5000)
      if (this.$route.query.filter) this.changeFilter(this.$route.query.filter)
    }
  },
  beforeMount () {
    this.fetchData()
  },
  beforeDestroy: function () {
    clearInterval(this.timer)
    clearInterval(this.interval)
  },
  watch: {
    '$route.params.address': {
      handler: function () {
        if (this.address !== this.$route.params.address) {
          this.loading = true
          this.address = this.$route.params.address
          this.filter = 'all'
          this.balance = ''
          this.wallet = null
          this.simulate = ''
          this.transactions = []
          this.params = {
            limit: 25,
            offset: 0
          }
          this.fetchData()
          this.counter++
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #442354 0%, #262B35 100%);
}
.transactions {
  margin-bottom: 1.5rem;
}
.balance {
  font-size: 2rem;
  font-weight: bold;
  .percents {
    padding-left: 0.25rem;
    font-size: 1rem;
  }
  .sign {
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
  }
}
.address-field {
  display: flex;
  align-items: center;
  .copy {
    margin-left: 0.5rem;
    cursor: pointer;
  }
  .address {
    max-width: 23.5rem;
  }
}
.image.is-256x256 {
  height: 256px;
  width: 256px;
  margin: auto;
}
.green {
  color: #57e426;
}
.filters {
  .column {
    font-size: 0.875rem;
    color: #acb1be;
    white-space: nowrap;
    text-align: center;
    line-height: 1rem;
    letter-spacing: 0.02em;
    font-weight: 300;
    background: #262b35;
    border: 1px solid #3b434f;
    z-index: 4;
    box-sizing: border-box;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: -1px;
    }
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &.is-active {
      position: relative;
      color: #57e426;
      border-color: #57e426;
      border-bottom-left-radius: 0;
      z-index: 5;
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-top: 0.5rem solid #57e426;
        border-right: 0.5rem solid transparent;
        bottom: -0.5625rem;
        left: -0.0625rem;
      }
    }
  }
}
.nfts {
  .nft {
    position: relative;
    border: 1px solid #3B434F;
    &.borderless {
      border: none;
    }
    border-radius: 0.25rem;
    transition: all 0.25s;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: auto;
      color: #17A89B;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      .loader {

      }
    }
    .noImage {
      font-size: 1rem;
      text-align: center;
      .hash {
        font-size: 0.825rem;
        font-family: monospace;
      }
    }
    .meta {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.5rem;
      max-height: 100%;
      overflow: scroll;
      background-color: rgba(59, 67, 79, 0.95);
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-word;
      word-break: break-word;
      -ms-hyphens: none;
      -moz-hyphens: none;
      -webkit-hyphens: none;
      hyphens: none;
      color: #fff;
      font-size: 0.75rem;
      visibility: hidden;
      border-top: 1px solid #ACB1BE;
      .key {
        color: #57e426;
        margin-right: 0.25em;
      }
    }
    &:hover {
      border-color: #ACB1BE;
      .meta {
        visibility: visible;
      }
    }
  }
}
</style>
