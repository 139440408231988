<template lang="pug">
  .transaction.full
    .media.amounts
      .media-left
        .arrow
          SvgIcon.icon(:name="sender && trx.type !== 'burnRod' ? 'green-arrow' : 'red-arrow'")
      .media-content
        span.amount(v-if="amount> 0" :class="{ 'sended': sender && trx.type !== 'burnRod' }")
          | {{ sender && trx.type !== 'burnRod' ? '+' : '-'}}{{ toUmi(amount) }}
          span(v-if="token")  {{ token }}
          SvgIcon.sign(v-else :name="sender && trx.type !== 'burnRod' ? 'sign_green' : 'sign_red'")
        span.fiat(v-if="amount> 0 && price") ≈ {{ pretty(amount / price) }}
        div(v-if="trx.type == 'transferNftWitness' && trx.nft")
          span.amount(:class="{ 'sended': sender }") {{ t('Transfer NFT') }} - &nbsp;
          span.is-size-5(v-clipboard:copy="trx.nft" v-clipboard:success="onCopy")
            router-link.fiat.dont-break-out.has-text-white(:to="{ name: 'Transaction', params: { transaction: trx.nft, lang: prefix }}") {{ cut(trx.nft, 6) }}
            span.hash(v-clipboard:copy="trx.nft" v-clipboard:success="onCopy")
              SvgIcon.icon(name="copy")

      .media-right
        span.confirmed
          | {{ trx.confirmed_at | moment("DD.MM.YY HH:mm:ss [GMT] Z") }}

    .columns.is-flex-mobile.is-marginless.addresses(v-if="action")
      .column.is-narrow.preview(v-if="trx.nftData && trx.nftData.url")
        img(v-show="isLoaded" @load="onImgLoad" :src="trx.nftData.url" :alt="trx.nftData.url")
      .column(style="justify-content: flex-end")
        router-link.is-size-5.has-text-warning(v-if="trx.type == 'mintNftWitness'" :to="{ name: 'Transaction', query: { block: trx.block_height }, params: { transaction: trx.hash, lang: prefix }}"
          ) {{ t('Mint NFT') }}
        .action(v-else :class="trx.type === 'burn' || trx.type === 'burnRod' ? 'has-text-danger' : 'has-text-success'") {{ action + ' ' }}
        router-link.dont-break-out(v-if="trx.recipient" :class="{ 'sender': sender, 'recipient': !sender }"
            :to="{ name: 'Address', params: { address: (sender ? trx.sender : trx.recipient) }}"
          ) {{ sender ? trx.sender : trx.recipient }}
      .column.is-narrow-mobile.is-narrow(v-if="trx.recipient" v-clipboard:copy="sender ? trx.sender : trx.recipient" v-clipboard:success="onCopy")
        SvgIcon.icon.copy(name="copy")

    .columns.is-flex-mobile.is-marginless.addresses(v-else-if="trx.sender && trx.recipient")
      .column.is-narrow.preview(v-if="trx.nftData && trx.nftData.url")
        img(v-show="isLoaded" @load="onImgLoad" :src="trx.nftData.url" :alt="trx.nftData.url")
      .column(style="justify-content: flex-end")
        router-link.dont-break-out(:class="{ 'sender': sender, 'recipient': !sender }"
            :to="{ name: 'Address', params: { address: (sender ? trx.sender : trx.recipient) }}"
          ) {{ sender ? trx.sender : trx.recipient }}
      .column.is-narrow-mobile.is-narrow(v-clipboard:copy="sender ? trx.sender : trx.recipient" v-clipboard:success="onCopy")
        SvgIcon.icon.copy(name="copy")

    .columns
      .column
        .hash
          | TX:
          |
          router-link.dont-break-out.with-margin(:to="{ name: 'Transaction', query: { block: trx.block_height }, params: { transaction: trx.hash, lang: prefix }}")
            | {{ cut(trx.hash, 15) }}
          span(v-clipboard:copy="$blockchain + 'transaction/' + trx.hash" v-clipboard:success="onCopy")
            SvgIcon.icon(name="copy")
      .column.is-narrow
        | {{ t('Block') }}:
        |
        a.lightgreen(:href="$blockchain + 'block/' + trx.block_height" target="_blank") {{ trx.block_height }}

</template>

<script>
export default {
  name: 'TrxSingle',
  data () {
    return {
      isLoaded: false
    }
  },
  computed: {
    direction () {
      return this.address && this.trx.sender !== this.address ? '+' : '-'
    },
    prefix () {
      return this.$translate.current !== 'en' ? this.$translate.current : null
    },
    sender () {
      return this.address && this.trx.sender !== this.address
    },
    isSmartTrx () {
      return !this.sender && this.trx.fee_address && this.trx.version <= 1
    },
    amount () {
      return this.isSmartTrx
        ? parseInt(this.trx.value || 0) + parseInt(this.trx.fee_value || 0)
        : parseInt(this.trx.value || 0)
    },
    token () {
      return this.trx.recipient && !this.trx.sender.startsWith('nft')
        ? this.trx.recipient.substr(0, 3).toUpperCase().replace('GLS', 'GLZ')
        : this.trx.sender.substr(0, 3).toUpperCase().replace('GLS', 'GLZ')
    },
    price () {
      return this.$store.getters.tokens[this.token] ? this.$store.getters.tokens[this.token].value : 0
    },
    action () {
      switch (this.trx.type) {
        case 'createStructure':
        case 'createSubstructure':
          return this.$translate.text('Create new Pool')
        case 'updateStructure':
          return this.$translate.text('Update structure')
        case 'changeProfitAddress':
          return this.$translate.text('Set profit address')
        case 'changeFeeAddress':
          return this.$translate.text('Set fee address')
        case 'activateTransitAddress':
          return this.$translate.text('Activate transit address')
        case 'deactivateTransitAddress':
          return this.$translate.text('Deactivate transit address')
        case 'burn':
        case 'burnRod':
          return this.$translate.text('Burning')
        case 'issue':
          return this.$translate.text('Minting tokens')
        case 'mintNftWitness':
          return this.$translate.text('Mint NFT')
        case 'updateSubstructure':
          return this.$translate.text('Update Pool name')
        default:
          return false
      }
    }
  },
  methods: {
    onImgLoad () {
      this.isLoaded = true
    }
  },
  props: {
    trx: {
      default: null
    },
    address: {
      default: null
    },
    mode: {
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.transaction {
  background-color: #1e232e;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  .arrow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #262b35;
    border: 1px solid #3b434f;
    box-sizing: border-box;
    border-radius: 1px 0.5rem 0.5rem 0.5rem;
    width: 2rem;
    height: 2rem;
    position: relative;
    margin-right: 0.5rem;
    .icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 0.5625rem;
      width: 0.8125rem;
    }
  }
  .amounts {
    display: flex;
    line-height: 2rem;
    font-weight: normal;
    font-size: 1.25rem;
    letter-spacing: 0.02em;
    color: #acb1be;
    white-space: nowrap;
    .amount {
      font-weight: bold;
      color: #eb5757;
      &.sended {
        color: #19ab4f;
      }
      span {
        margin-right: 0.5rem;
      }
    }
    .sign {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5em;
      align-self: center;
    }
  }
  .preview {
    max-width: 6rem;
    max-height: 6rem;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2.5rem;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.02em;
    .confirmed {
      font-family: "PT Mono";
      color: #acb1be;
    }
    .address {
      a {
        color: #ffffff;
        &:hover {
          color: #57e426;
        }
      }
    }
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.625rem;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
    }
  }
  &.full {
    padding: 1rem;
    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
    background-color: #262b35;
    &:nth-child(even) {
      background-color: transparent;
      border: 1px solid #282e39;
    }
    &:nth-child(odd) {
      .addresses {
        .arrow {
          border-color: transparent;
          background: transparent;
        }
      }
    }
    .action:not(:last-child) {
      margin-right: 1.5rem;
    }
    .amounts {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 1rem;
      border-bottom: 1px dashed #3b434f;
      .arrow {
        margin-right: 0;
      }
      .amount {
        font-size: 1.3125rem;
      }
      .sign {
        margin-left: 0.5rem;
        width: 1rem;
        height: 1rem;
        vertical-align: baseline;
      }
      .fiat {
        font-family: "PT Mono";
        font-weight: normal;
        font-size: 1.125rem;
        color: #acb1be;
      }
      .confirmed {
        font-size: 0.825rem;
        color: #acb1be;
      }
    }
    .addresses {
      text-align: right;
      padding: .5rem 0;
      border-bottom: 1px dashed #3B434F;
      margin-bottom: 0.75rem !important;
      a {
        font-weight: normal;
        font-size: 1rem;
        line-height: 1rem;
        color: #ffffff;
        //max-width: 16rem;
        // &.sender {
        //   color: #19AB4F;
        // }
        // &.recipient {
        //   color: #EB5757;
        // }
        &:hover {
          opacity: 0.75;
        }
      }
      .column {
        display: flex;
        align-items: center;
      }
      .copy {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
        &:hover {
          opacity: 0.75;
        }
      }
      .arrow {
        margin: auto;
        //margin: auto 2.5rem;
      }
    }
    .hash {
      a {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        -ms-hyphens: none;
        -moz-hyphens: none;
        -webkit-hyphens: none;
        hyphens: none;
      }
      .icon {
        cursor: pointer;
        margin-left: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
}
.amount {
  white-space: nowrap;
}
</style>
