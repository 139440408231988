<template lang="pug">
.speedometer.columns
  .column
    .background
      .svg-background
        svg(viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg")
          circle(
            id="background"
            :r="radius"
            cx="50%"
            cy="50%"
            :stroke='color === "green" ? "#57E426" : "#F51500"'
            :stroke-width="stroke"
            stroke-dasharray="237"
            fill="none"
            opacity="0.2"
          )
          circle(
            id="fill"
            :r="radius"
            cx="50%"
            cy="50%"
            :stroke='color === "green" ? "#57E426" : "#F51500"'
            :stroke-width="stroke"
            :stroke-dasharray="filled + ', 360'"
            fill="none"
          )
      .arrow(ref="arrow" v-html)
        <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.9369 3.16811L0.537813 29.4444C0.255575 29.8085 0.110629 30.2602 0.128379 30.7205C0.146129 31.1808 0.325442 31.6202 0.634892 31.9617C0.944342 32.3033 1.36418 32.5251 1.82082 32.5884C2.27746 32.6517 2.74177 32.5524 3.13235 32.3079L31.3242 14.6322C33.0552 13.5128 34.2752 11.7573 34.7205 9.74556C35.1657 7.73378 34.8004 5.62742 33.7035 3.88212C32.6066 2.13682 30.8663 0.892895 28.859 0.419421C26.8518 -0.0540526 24.739 0.280987 22.9777 1.35207C22.1984 1.83621 21.5081 2.45048 20.9369 3.16811Z" :fill='color === "green" ? "#57E426" : "#F51500"' />
        </svg>
  .column.is-narrow
    .content.has-text-right-mobile
      p {{ t('Transactions per second') }}
      p.numbers
        span.has-text-white {{ current }}
        |  / {{ convert(max) }}
      p
        span.has-text-white {{ t('Now') }}
        |  / {{ t('Maximum') }}
</template>

<script>
export default {
  name: 'Speedometer',
  props: {
    min: {
      default: 0
    },
    max: {
      default: 4369
    },
    current: {
      default: 0
    },
    color: {
      type: String,
      default: 'green'
    }
  },
  data () {
    return {
      deg: 0,
      radius: 53,
      stroke: 15
    }
  },
  computed: {
    angle () {
      return parseFloat(this.current / (this.max / 260) + 2).toFixed(2)
    },
    filled () {
      return parseInt(this.current / (this.max / 237)) + 0.1
    }
  },
  methods: {
    rotate () {
      this.$refs.arrow.style.transform = 'rotate(' + this.angle + 'deg)'
    }
  },
  watch: {
    angle () {
      this.rotate()
    }
  }
}
</script>

<style scoped lang="scss">
.speedometer {
  display: flex;
  align-content: space-between;
  align-items: center;
  .background {
    display: none;
    position: relative;
    width: 7.5625rem;
    height: 7.5625rem;
    margin: 0 auto;
    // background-image: url(../../assets/speed.png);
    .svg-background {
      width: 100%;
      height: 100%;
      transform: rotate(141deg);
    }
    .arrow {
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 3.125rem;
      left: 1.925rem;
      transform-origin: 91% 28%;
    }
  }
  .content {
    margin-left: 0.75rem;
    @media screen and (min-width: 428px) {
      min-width: 11rem;
    }
    p {
      color: #ACB1BE;
      font-size: 0.875rem;
      white-space: nowrap;
      margin-bottom: 0.75rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .numbers {
      font-family: "PT Mono";
      font-size: 1.125rem;
    }
  }
}
</style>
