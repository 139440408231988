<template lang="pug">
  div
    .top
      h1(v-translate) Blocks
      Widgets(:stats="blocksStats")

    b-table.table.is-fullwidth(
      v-if="data.length > 0"
      :data="data"

      paginated
      backend-pagination
      :total='this.summary || 0'
      :per-page='perPage'
      @page-change="onPageChange"
      :current-page.sync="page"
      range-before="3"
      range-after="3"

      :mobile-cards='true'
      :default-sort="['height', 'desc']"
    )
      template( slot-scope="props" )
        b-table-column(
          field = "height"
          :label = "t('Height')"
        )
          router-link.hash(:to="{ name: 'Block', params: { height: props.row.height, lang: prefix }}") {{ props.row.height }}
        b-table-column(
          field = "transactions"
          :label = "t('Transactions')"
          :numeric = "true"
        ) {{ props.row.tx_count }}
        b-table-column(
          v-if = "props.row.amount && props.row.amount > 0"
          field = "amount"
          :label = "t('Amount') + ' (UMI)'"
          :numeric = "true"
        ) {{ toUmi(props.row.amount + props.row.fee_amount) }}
        b-table-column(
          v-if="props.row.amount && props.row.amount > 0"
          field = "amount"
          :label = "t('Amount') + ' (' + currency + ')'"
          :numeric = "true"
        ) {{ pretty(props.row.amount + props.row.fee_amount, false) }}
        b-table-column(
          field = "date"
          :label = "t('Timestamp')"
          :numeric = "true"
        ) {{ props.row.created_at | moment("DD.MM.YY HH:mm:ss") }}
        b-table-column(
          field = "hash"
          label = "Hash"
          :numeric = "true"
        )
          router-link.hash(:to="{ name: 'Block', params: { height: props.row.height, lang: prefix }}") {{ cut(props.row.hash, 6) }}
    .loader(v-else)
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api/explorer'

export default {
  name: 'BlocksTab',
  data () {
    return {
      data: [],
      stats: {},
      loading: false,
      page: 1,
      perPage: 25,
      interval: null,
      getBlocks: null,
      summary: 0
    }
  },
  computed: {
    ...mapGetters(['currency']),
    prefix () {
      return this.$translate.current !== 'en' ? this.$translate.current : null
    },
    timing () {
      return !this.stats.created_at
        ? 0
        : ((new Date().getTime() - new Date(this.stats.created_at).getTime()) / 1000).toFixed(2)
    },
    blocksStats () {
      return [
        {
          number: this.summary || 0,
          label: this.$translate.text('Number of blocks'),
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          number: this.stats.transactions_per_block,
          label: this.$translate.text('Transactions in block (24 hours)'),
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        // {
        //   number: this.stats.active_addresses || 0,
        //   label: this.$translate.text('Active addresses (24 hours)'),
        //   customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        // },
        {
          timestamp: this.stats.created_at || new Date(),
          label: this.$translate.text('Last block time'),
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          value: this.timing + ' ' + this.$translate.text('sec'),
          label: this.$translate.text('Since the last block has passed'),
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        },
        {
          number: this.stats.blocks_per_hour,
          label: this.$translate.text('Blocks per hour (24 hours)'),
          customClass: 'is-12-mobile is-6-tablet is-4-desktop'
        }
      ]
    }
  },
  methods: {
    loadAsyncData () {
      this.loading = true
      const params = {
        limit: this.perPage,
        offset: this.page === this.summary ? 0 : (this.perPage * this.page)
      }
      api
        .getBlocks(params)
        .then(({ data }) => {
          this.data = data.data
          this.summary = this.page === 1 ? this.data[0].height : this.stats.summary
          this.loading = false
        })
        .catch(() => {
          this.data = []
          this.loading = false
        })
      api.getBlocksStats()
        .then(({ data }) => {
          this.stats = data.data
          this.summary = this.page === 1 ? this.data[0].height : this.stats.summary
        })
        .catch(() => {
          this.data = []
          this.loading = false
        })
    },
    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    }
  },
  created () {
    if (this.$route.query && this.$route.query.page) {
      this.page = parseInt(this.$route.query.page)
    } else {
      this.page = 1
    }

    this.loadAsyncData()
    this.getBlocks = setInterval(() => {
      if (this.page === 1) {
        this.loadAsyncData()
      }
    }, 7000)
  },
  beforeDestroy: function () {
    clearInterval(this.getBlocks)
  },
  watch: {
    page () {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            page: this.page || 1
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
}
</script>

<style scoped>
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #234254 0%, #262B35 100%);
}
.svg-icon {
  vertical-align: top;
}
</style>
