<template lang="pug">
  .transaction(v-if="trx")
    .columns.is-vcentered.dotted.is-multiline
      .column.is-12-mobile(v-if="trx.type == 'transferNftWitness' && trx.nft")
        span.price
          span.has-text-success {{ t('Transfer NFT') }} -
          span(v-clipboard:copy="$blockchain + 'transaction/' + trx.nft" v-clipboard:success="onCopy")
            | {{ cut(trx.nft, 6) }}
            SvgIcon.copy(name="content-copy")

      .column.is-12-mobile(v-else :style="trx.type === 'burnRod' ? 'color: #EB5757;' : ''")
        span.price(v-if="trx.value") {{ toUmi(amount) }}
          SvgIcon.sign(v-if="token === 'UMI'" name="sign")
          span(v-else) {{ ' ' + token }}
        span(v-if="price") ≈
        span.fiat(v-if="price") {{ pretty(amount / price) }}

      .column.is-12-mobile
        .is-pulled-right-desktop
          .block
            | {{ t('Block') }}:
            router-link(:to="{ name: 'Block', params: { height: trx.block_height, lang: prefix }}") {{ trx.block_height }}
          .date {{ trx.confirmed_at | moment("DD.MM.YY HH:mm:ss [GMT] Z") }}

    .addresses.columns.is-v-centered.is-flex-mobile.is-multiline(:class="{ 'is-vcentered': !trx.fee_address }")
      .column.is-12-mobile( v-if='trx.type !== "burnRod"' )
        .address.sender(:class="{ 'has-text-right': trx.type == 'issue' }")
          router-link.dont-break-out(
            v-if="trx.type !== 'issue'"
            :to="{ name: 'Address', params: { address: trx.sender, lang: prefix }}"
          ) {{ trx.sender }}
          router-link.is-size-5(
            v-else
            :to="{ name: 'Address', params: { address: trx.sender, lang: prefix }}"
          ) {{ t('Minting tokens') }}

      .column.is-narrow.is-narrow-mobile( v-if='trx.type !== "burnRod"' )
        .arrow
          SvgIcon.icon(name="green-arrow")

      .column
        .address.recipient
          .pricing(v-if="trx.fee_address")
            span.price {{ toUmi(trx.value) }}
              SvgIcon.sign(v-if="token === 'UMI'" name="sign")
              span(v-else) {{ ' ' + token }}
            | ≈
            span.fiat(v-if="price") {{ pretty(trx.value / price) }}
          router-link.is-size-5(
            v-if="trx.type == 'burn' || trx.type == 'burnRod'"
            :to="{ name: 'Burned' }"
          ) {{ t('Burning') }}
          router-link.is-size-5.has-text-warning(
            v-if="trx.type == 'mintNftWitness' && trx.type !== 'burnRod'"
            :to="{ name: 'Transaction', query: { block: trx.block_height }, params: { transaction: trx.hash, lang: prefix }}"
          ) {{ t('Mint NFT') }}
          router-link.dont-break-out(
            v-else-if="trx.recipient && trx.type !== 'burnRod'"
            :to="{ name: 'Address', params: { address: trx.recipient, lang: prefix }}"
          ) {{ trx.recipient }}
          span.action(v-else-if="trx.type !== 'burnRod'") {{ action }}

        .address.recipient(v-if="trx.recipient && trx.fee_address")
          .pricing(v-if="trx.fee_value")
            span.price {{ toUmi(trx.fee_value) }}
              SvgIcon.sign(v-if="token === 'UMI'" name="sign")
              span(v-else) {{ ' ' + token }}
            | ≈
            span.fiat(v-if="price") {{ pretty(trx.fee_value / price) }}
          router-link.dont-break-out(
            v-if="trx.fee_address"
            :to="{ name: 'Address', params: { address: trx.fee_address, lang: prefix }}"
          ) {{ trx.fee_address }}

      .column.is-12-mobile
        | TX:
        router-link.dont-break-out.with-margin(:to="{ name: 'Transaction', query: { block: trx.block_height }, params: { transaction: trx.hash, lang: prefix }}")
          | {{ trx.hash }}
        span(v-clipboard:copy="$blockchain + 'transaction/' + trx.hash" v-clipboard:success="onCopy")
          SvgIcon.copy(name="content-copy")

    .columns.nft(v-if="trx.nftData")
      .column
        .content
          a(:href="trx.nftData.url" target="_blank")
            figure
              img(v-show="isLoaded" @load="onImgLoad" :src="trx.nftData.url" :alt="trx.nftData.url")
              iframe(v-if="trx.nftData.meta && trx.nftData.meta.contentType === 'text/plain'" :src="trx.nftData.url")
              figcaption {{ trx.nftData.url }}

</template>

<script>
import { mapGetters } from 'vuex'
import DashedLine from '@/components/common/DashedLine'

export default {
  name: 'Transaction',
  data () {
    return {
      isLoaded: false
    }
  },
  components: { DashedLine },
  props: {
    trx: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['tokens']),
    prefix () {
      return this.$translate.current !== 'en' ? this.$translate.current : null
    },
    amount () {
      return parseInt(this.trx.value || 0) + parseInt(this.trx.fee_value || 0)
    },
    action () {
      switch (this.trx.type) {
        case 'createStructure':
        case 'createSubstructure':
          return this.$translate.text('Create new Pool')
        case 'updateStructure':
          return this.$translate.text('Update structure')
        case 'changeProfitAddress':
          return this.$translate.text('Set profit address')
        case 'changeFeeAddress':
          return this.$translate.text('Set fee address')
        case 'activateTransitAddress':
          return this.$translate.text('Activate transit address')
        case 'deactivateTransitAddress':
          return this.$translate.text('Deactivate transit address')
        case 'burn':
        case 'burnRod':
          return this.$translate.text('Burning')
        case 'issue':
          return this.$translate.text('Minting tokens')
        case 'mintNftWitness':
          return this.$translate.text('Mint NFT')
        case 'transferNftWitness':
          return this.$translate.text('Transfer NFT')
        case 'updateSubstructure':
          return this.$translate.text('Update Pool name')
        default:
          return this.$translate.text('Burning')
      }
    },
    token () {
      if (this.trx.sender.startsWith('nft') && this.trx.recipient) return 'NFT'
      return this.trx.recipient
        ? this.trx.recipient.substr(0, 3).toUpperCase().replace('GLS', 'GLZ')
        : this.trx.sender.substr(0, 3).toUpperCase().replace('GLS', 'GLZ')
    },
    price () {
      return this.$store.getters.tokens[this.token] ? this.$store.getters.tokens[this.token].value : 0
    }
  },
  methods: {
    onImgLoad () {
      this.isLoaded = true
    }
  }
}
</script>

<style scoped lang="scss">
.transaction {
  background-color: #262B35;
  border: 1px solid #282E39;
  box-sizing: border-box;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0.7rem 1rem;
  .arrow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #262b35;
    border: 1px solid #3b434f;
    box-sizing: border-box;
    border-radius: 1px 0.5rem 0.5rem 0.5rem;
    width: 2rem;
    height: 2rem;
    position: relative;
    .icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 0.5625rem;
      width: 0.8125rem;
    }
  }
  .block {
    text-align: right;
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 0.25rem;
    a {
      padding-left: 0.325rem;
    }
  }
  .date {
    text-align: right;
    font-size: 0.875rem;
    color: #ACB1BE;
  }
  .price {
    font-weight: bold;
    font-size: 1.3125rem;
    .sign {
      margin: 0 0.5rem 0 0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: baseline;
    }
    span {
      margin-right: 0.5rem;
    }
  }
  .fiat {
    font-weight: normal;
    font-family: "PT Mono";
    font-size: 1.125rem;
    letter-spacing: 0.02em;
    padding-left: 0.25rem;
  }
  .dotted {
    border-bottom: 1px dashed #3B434F;
  }
  .copy {
    margin-left: 0.5em;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    vertical-align: middle;
  }
  .pricing {
    &.level {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .price {
      font-weight: 300;
      font-size: 0.875rem;
      .sign {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
    .fiat {
      font-size: 0.875rem;
    }
  }
  .addresses {
    font-size: 0.875rem;
    .sender a,
    .sender .action {
      color: #19AB4F;
    }
    .recipient a,
    .recipient .action {
      color: #EB5757;
    }
    .address:not(:first-child) {
      margin-top: 0.5rem;
    }
    .pricing {
      font-size: 1rem;
    }
  }
  .with-margin {
    margin-left: 0.25rem;
  }
  &:nth-child(even) {
    background-color: transparent;
    border: 1px solid #282e39;
  }
  .nft {
    .content {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      -ms-hyphens: none;
      -moz-hyphens: none;
      -webkit-hyphens: none;
      hyphens: none;
      max-width: 40rem;
      margin: 0 auto;
      iframe,
      img {
        display: block;
        max-width: 100%;
        max-height: 40rem;
        width: auto;
        height: auto;
        margin: 0 auto 1rem;
        background-color: #fff;
      }
    }
    .meta {

    }
  }
}
</style>
