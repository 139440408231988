<template lang="pug">
div
  .top
    .container
      .columns.is-vcentered.is-flex-mobile.is-multiline
        .column.is-6-desktop.is-12-mobile.is-flex.is-align-items-center.is-justify-content-flex-start.is-variable
          img.logo(v-webp="require(`@/assets/rod.png`)")
          h1.ml-2 ROD
        .column.is-6-desktop.is-12-mobile
          Speedometer(
            min="0"
            :max="stats.transactions.bandwidth"
            :current="stats.transactions.per_second"
            color="red"
          )
  .layout
    .item
      StatItem(
        v-for="(item, i) in stakeStats"
        :key="'stake' + i"
        :item="item" )
      StatItem(
        v-for="(item, i) in blocksStats"
        :key="'blocks' + i"
        :item="item" )
    .item
      StatItem(
        v-for="(item, i) in commonStats"
        :key="'common' + i"
        :item="item" )
      
      StatItem(
        v-for="(item, i) in transactionsStats"
        :key="'transactions' + i"
        :item="item" )
  //- .columns
    .column
      DashedLine(
        v-for="(item, i) in stakeStats"
        :key="'stake' + i"
        :item="item"
      )
    .column.is-offset-1-desktop
      DashedLine(
        v-for="(item, i) in commonStats"
        :key="'common' + i"
        :item="item"
      )
  //- .columns
    .column
      DashedLine(
        v-for="(item, i) in blocksStats"
        :key="'blocks' + i"
        :item="item"
      )
    .column.is-offset-1-desktop
      DashedLine(
        v-for="(item, i) in transactionsStats"
        :key="'transactions' + i"
        :item="item"
      )

</template>

<script>
// import DashedLine from '@/components/common/DashedLine'
import StatItem from "@/components/common/StatItem"
import Speedometer from '@/components/common/Speedometer'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Rod',
  components: { StatItem, Speedometer },
  data () {
    return {
      test: 0,
      interval: null
    }
  },
  mounted () {
    // fetch(`https://sigen.pro/public-exchange/rod-price`)
    //   .then(res => res.json())
    //   .then(data => this.courses = data)

    this.$store.dispatch('getRodStats')
    this.interval = setInterval(() => {
      this.$store.dispatch('getRodStats')
    }, 6 * 1000)
  },
  computed: {
    ...mapGetters(['rod', 'currency']),
    ...mapState(['rodRates']),
    stats () {
      return this.rod?.stats
    },
    stakeStats () {
      return [
        {
          value: this.toUmi(this.stats.status.staking, 0) + " ROD",
          label: this.$translate.text('In staking')
        },
        {
          value: this.toUmi(this.stats.status.exchange, 0) + " ROD",
          label: this.$translate.text('On the exchange')
        },
        {
          value: this.toUmi(this.stats.status.trading, 0) + " ROD",
          label: this.$translate.text('At trading addresses')
        },
        {
          value: this.convert(this.stats.status.trading_addressses),
          label: this.$translate.text('Trading addresses')
        },
        {
          value: this.convert(this.stats.status.staking_addresses),
          label: this.$translate.text('Staking addresses')
        },
        {
          value: this.toUmi(this.stats.status.coins, 0) + " ROD",
          label: this.$translate.text('Total tokens')
        },
        {
          value: this.toUmi(this.stats.status.emission, 0) + " ROD",
          label: this.$translate.text('Initial Emission')
        },
        {
          value: this.convert(21000000000000),
          label: this.$translate.text('End emission') + " ROD"
        },
        {
          value: this.convert(this.stats.transactions.summary) || 0,
          label: this.$translate.text('Transactions')
        },
        {
          value: this.convert(this.stats.blocks.summary) || 0,
          label: this.$translate.text('Blocks')
        }
      ]
    },
    blocksStats () {
      return [
        {
          value: parseInt(this.stats.blocks.seconds_between_blocks),
          label: this.$translate.text('Block time'),
          suffix: ' ' + this.$translate.text('sec')
        },
        {
          value: parseInt(this.stats.blocks.transactions_in_last_block),
          label: this.$translate.text('Transactions in block')
        },
      ]
    },
    transactionsStats () {
      return [
        {
          value: parseInt(this.stats.transactions.per_min),
          label: this.$translate.text('Transactions per min')
        },
        {
          value: this.convert(this.stats.transactions.per_hour),
          label: this.$translate.text('Transactions per hour')
        },
        {
          value: this.convert(this.stats.transactions.per_day),
          label: this.$translate.text('Daily transactions')
        }
      ]
    },
    commonStats () {
      return [
        {
          label:
            this.$translate.text('Trading volume,\nCommon'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_all, 0) + ' ROD</span> \n/ ' + this.toUmi(this.stats.volume_all * this.rodRates[this.currency], this.currency === "BTC" ? 4 : 0) + " " + this.currency
        },
        {
          label:
            this.$translate.text('Trading volume,\nP2P'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_p2p, 0) + ' ROD</span> \n/ ' + this.toUmi(this.stats.volume_p2p * this.rodRates[this.currency], this.currency === "BTC" ? 4 : 0) + " " + this.currency
        },
        {
          label:
            this.$translate.text('Trading volume,\nExchange'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_exchange, 0) + ' ROD</span> \n/ ' + this.toUmi(this.stats.volume_exchange * this.rodRates[this.currency], this.currency === "BTC" ? 4 : 0) + " " + this.currency
        },
        {
          label: this.$translate.text('Liquidity,\nCommon') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.liquid ? this.toUmi(this.stats.liquid.bids[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.liquid ? this.toUmi(this.stats.liquid.asks[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Liquidity, P2P') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.p2p_liquid ? this.toUmi(this.stats.p2p_liquid.bids[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.p2p_liquid ? this.toUmi(this.stats.p2p_liquid.asks[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Liquidity, Exchange') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.exchange_liquid ? this.toUmi(this.stats.exchange_liquid.bids[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.exchange_liquid ? this.toUmi(this.stats.exchange_liquid.asks[this.currency], this.currency === "BTC" ? 4 : 0, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Capitalization'),
          value: this.toUmi(this.stats.status.coins * this.rodRates[this.currency], this.currency === "BTC" ? 6 : 0) + " " + this.currency
        },
        {
          value: this.convert(100000),
          label: this.$translate.text('Create structure fee')
        }
      ]
    }
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">

.top {
  background: radial-gradient(
    86.57% 1033.5% at 96.99% 13.43%,
    rgba(#F51500, .1) 0%,
    #262b35 100%
  );
  display: flex;
  align-items: center;
  .logo {
    object-fit: contain;
    max-width: 7rem;
    width: 100%
  }

  h1 {
    font-family: Merriweather!important;
  }

  @media screen and (max-width: 768px) {
    .column:first-of-type {
      justify-content: center!important;
    }
    
  }
}

.layout {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  row-gap: 2rem;
  // @media screen and (max-width: 1024px) {
  //   grid-template-columns: repeat(2, minmax(0, 1fr));
  // }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
