<template lang="pug">
  .level
    .level-left.has-text-centered.is-hidden-mobile
      span.is-ultralight {{ t('Page:') }}
      input.input(
        type="number"
        v-model.number="current"
      )
    .level-right
      b-pagination(
        :total="total"
        :current.sync="current"
        :range-before="rangeBefore"
        :range-after="rangeAfter"
        :order="order"
        :size="size"
        :simple="isSimple"
        :rounded="isRounded"
        :per-page="perPage"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page")
        b-pagination-button(
          slot-scope="props"
          :page="props.page"
          :id="`page${props.page.number}`"
          tag="router-link"
          :to="`?page=${props.page.number}`"
        )
</template>

<script>
export default {
  data () {
    return {
      search: null,
      rangeBefore: 2,
      rangeAfter: 1,
      current: 1,
      order: '',
      size: '',
      isSimple: false,
      isRounded: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right'
    }
  },
  props: {
    total: { type: Number, default: 0 },
    perPage: { type: Number, default: 0 }
  },
  mounted () {
    if (this.$route.query && this.$route.query.page) { this.current = parseInt(this.$route.query.page) }
    if (this.current < 1) { this.current = 1 }
    if (this.current > 1) {
      this.$emit('change', {
        limit: this.perPage,
        offset: this.perPage * (this.current - 1)
      })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal) {
        if (newVal.query && newVal.query.page) {
          this.current = parseInt(newVal.query.page)
        }
      }
    },
    current: function (value) {
      if (value <= this.total) {
        this.$emit('change', {
          limit: this.perPage,
          offset: this.perPage * (parseInt(value) - 1)
        })
      }
    }
  }
}
</script>

<style scoped>
  .level {
    overflow: hidden;
  }
  span.is-ultralight {
    line-height: 2.25em;
  }
  .input {
    margin-left: .75rem;
    outline: none;
    box-shadow: none;
    width: 5.25rem;
    text-align: center;
    border-color: #252A32;
    color: white;
    background-color: #1E2329;
  }
</style>
