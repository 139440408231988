<!-- //- .dashed(:class="{'shorted': label.length > 15}")
  .left {{ item.label }}
  .center
  .right
    a.shorted(v-if="item.link" :href="item.link") {{ label }}
    template(v-else) {{ label }} -->
<template lang="pug">
.stat-item
  p.heading {{ item.label }}
  a.title(v-if="item.link" :href="item.link" v-html='label')
  p.title(v-else v-html='label')
</template>

<script>
export default {
  name: 'DashedLine',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    label () {
      return this.item.value + (this.item.suffix ? this.item.suffix : '')
    }
  }
}
</script>

<style scoped lang="scss">
.stat-item {
  text-align: left;
}
.heading {
  color: #BDC1D6;
}
.title {
  font-family: "PT Mono";
  font-size: 1.125rem;
  line-height: 1.25rem;
  white-space: nowrap;
  margin-top: .5rem;
}
.title ::v-deep .has-text-red {
  color: rgb(245, 21, 0);
}
.title ::v-deep .has-text-white {
  color: #fff;
}
p.title {
  color: #fff;
}
a.title {
  color: #17A89B;
}
a:hover {
  text-decoration: underline!important;
}
</style>