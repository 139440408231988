<template lang="pug">
  div
    .top
      h1(v-translate) Burned in network
      Widgets(:stats="statistics")

    .transactions(v-if="data && data.length > 0")
      Trx(
        v-for="(trx, i) in data"
        :key="i"
        :trx="trx"
      )
    .loader(v-else-if="stats.burned && stats.burned.total > 0")
    p(v-else) Nothing found

    Pagination(
      v-if="data && data.length > 0"
      @change="pagination"
      :perPage="params.limit"
      :total="stats.burned ? stats.burned.total : 0"
    )

</template>

<script>
import Pagination from '@/components/common/Pagination'
import Trx from '@/components/explorer/Trx'
import { mapGetters } from 'vuex'
import api from '@/api/explorer'

export default {
  name: 'BurnedTab',
  components: { Pagination, Trx },
  computed: {
    ...mapGetters(['currency']),
    statistics () {
      if (!this.stats.burned) return []
      return [
        {
          label: this.$translate.text('Burn transactions'),
          number: this.stats ? this.stats.burned.total : 0,
          customClass: 'is-12-mobile is-4-tablet is-4-desktop'
        },
        {
          label: this.$translate.text('Burned total'),
          value: (this.toUmi(this.stats.burned.value, 100, true) || 0) + ' UMI',
          customClass: 'is-12-mobile is-4-tablet is-4-desktop'
        },
        {
          label: this.$translate.text('Burned today'),
          value: (this.toUmi(this.stats.burned.value_per_day) || 0) + ' UMI',
          suffix: 'UMI',
          customClass: 'is-12-mobile is-4-tablet is-4-desktop'
        }
      ]
    }
  },
  data () {
    return {
      loading: false,
      data: [],
      stats: {},
      params: {
        limit: 10,
        offset: 0
      },
      trxInterval: null
    }
  },
  methods: {
    pagination (params) {
      if (params && params.offset >= 0 && params.limit > 0) {
        this.params = params
        this.fetchTrxs()
        clearInterval(this.trxInterval)
      } else {
        this.updateTrxs()
      }
    },
    updateTrxs () {
      this.trxInterval = setInterval(() => {
        this.fetchTrxs()
      }, 60000)
    },
    fetchTrxs () {
      this.loading = true
      this.params.version = 15
      api
        .getTransactions(this.params)
        .then(({ data }) => {
          this.data = data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    updateStats () {
      this.statsInterval = setInterval(() => {
        this.fetchStats()
      }, 5000)
    },
    fetchStats () {
      api.getTransactionsStats()
        .then(({ data }) => {
          this.stats = data.data
          if (this.stats.burned.total > 0) {
            this.fetchTrxs()
            this.updateTrxs()
            this.updateStats()
          } else {
            this.loading = false
          }
        })
    },
    fetchData () {
      this.fetchStats()
    }
  },
  beforeMount () {
    this.fetchData()
  },
  beforeDestroy: function () {
    clearInterval(this.trxInterval)
    clearInterval(this.statsInterval)
  }
}
</script>

<style scoped>
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #c59f22
 0%, #262B35 100%);
}
.transactions {
  margin-bottom: 1.5rem;
}
</style>
