<template lang="pug">
div
  .top
    img.logo(v-webp="require(`@/assets/glize.png`)")
    h1 Glize
  .layout
    .item
      StatItem(
        v-for="(item, i) in emission"
        :key="'emission' + i"
        :item="item"
      )
      StatItem(
        v-for="(item, i) in staking"
        :key="'staking' + i"
        :item="item"
      )
    .item
      StatItem(
        v-for="(item, i) in commonStats"
        :key="'common' + i"
        :item="item"
      )
    //- .column
    //-   DashedLine(
    //-     v-for="(item, i) in emission"
    //-     :key="'emission' + i"
    //-     :item="item"
    //-   )
    //- .column
    //-   DashedLine(
    //-     v-for="(item, i) in commonStats"
    //-     :key="'common' + i"
    //-     :item="item"
    //-   )
    //- .column
    //-   DashedLine(
    //-     v-for="(item, i) in staking"
    //-     :key="'staking' + i"
    //-     :item="item"
    //-   )

</template>

<script>
// import DashedLine from '@/components/common/DashedLine'
import StatItem from "@/components/common/StatItem"
import { mapGetters, mapState } from 'vuex'

import axios from 'axios'

export default {
  name: 'Glize',
  components: { StatItem },
  data () {
    return {
      totalBalance: 0
    }
  },
  computed: {
    ...mapGetters(['structures', 'api', 'currency']),
    ...mapState({
      stats: state => state.glz.stats,
      glzRates: state => state.glzRates
    }),
    glz () {
      return this.structures.filter(item => item.prefix === 'glz')[0]
    },
    gls () {
      return this.structures.filter(item => item.prefix === 'gls')[0]
    },
    // totalBalance () {
    //   return this.glz ? (this.glz.supply / 100) : 0
    // },
    emission () {
      return [
        {
          value: this.convert(this.totalBalance),
          label: this.$translate.text('Current emission')
        },
        {
          // value: this.convert(1000000000),
          value: this.convert(this.totalBalance),
          label: this.$translate.text('End emission')
        },
        {
          value: this.glz ? this.convert(this.glz.addressCount) : 0,
          label: this.$translate.text('Trading addresses') + ' ' + '(GLZ)'
        },
        {
          value: this.gls ? this.convert(this.gls.addressCount) : 0,
          label: this.$translate.text('Staking addresses') + ' ' + '(GLS)'
        }
      ]
    },
    staking () {
      return [
        {
          value: this.gls ? this.convert(this.gls.balance / 100) : 0,
          label: this.$translate.text('In staking')
        },
        // {
        //   value: (!this.gls || this.gls.interestRate === 0)
        //     ? '-'
        //     : (this.totalBalance <= 250000000)
        //         ? this.$translate.text('red_stage')
        //         : (this.totalBalance <= 500000000)
        //             ? this.$translate.text('blue_stage')
        //             : this.$translate.text('green_stage'),
        //   label: this.$translate.text('Staking stage')
        // },
        {
          value: this.gls ? `${this.gls.deposit_percent / 100}%` : 0,
          label: this.$translate.text('Staking percent')
        },
        {
          value: this.convert(this.stats.transactions.summary) || 0,
          label: this.$translate.text('Transactions')
        },
      ]
    },
    commonStats () {
      return [
      {
          label:
            this.$translate.text('Trading volume,\nCommon'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_all, 0) + ' GLZ</span> \n/ ' + this.toUmi(this.stats.volume_all / 100 * this.glzRates[this.currency], this.currency === "BTC" ? 4 : 2, 1) + " " + this.currency
        },
        {
          label:
            this.$translate.text('Trading volume,\nP2P'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_p2p, 0) + ' GLZ</span> \n/ ' + this.toUmi(this.stats.volume_p2p / 100 * this.glzRates[this.currency], this.currency === "BTC" ? 4 : 2, 1) + " " + this.currency
        },
        {
          label:
            this.$translate.text('Trading volume,\nExchange'),
            //  + '\nROD/ ' + this.currency,
          link: this.$website + 'volumes',
          value: '<span class="has-text-white">' + this.toUmi(this.stats.volume_exchange, 0) + ' GLZ</span> \n/ ' + this.toUmi(this.stats.volume_exchange / 100 * this.glzRates[this.currency], this.currency === "BTC" ? 4 : 2, 1) + " " + this.currency
        },
        {
          label: this.$translate.text('Liquidity,\nCommon') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.liquid ? this.toUmi(this.stats.liquid.bids[this.currency], this.currency === "BTC" ? 4 : 2, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.liquid ? this.toUmi(this.stats.liquid.asks[this.currency], this.currency === "BTC" ? 4 : 2, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Liquidity, P2P') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.p2p_liquid ? this.toUmi(this.stats.p2p_liquid.bids[this.currency], this.currency === "BTC" ? 4 : 2, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.p2p_liquid ? this.toUmi(this.stats.p2p_liquid.asks[this.currency], this.currency === "BTC" ? 4 : 2, 1) : 0) + ' ' + this.currency + "</span>"
        },
        {
          label: this.$translate.text('Liquidity, Exchange') + '\n(' + this.$translate.text('buy') + ' / ' + this.$translate.text('sell') + ')',
          link: this.$website + 'liquidity',
          value: (this.stats.exchange_liquid ? this.toUmi(this.stats.exchange_liquid.bids[this.currency], this.currency === "BTC" ? 4 : 2, 1) : 0) + ' ' + this.currency +
            '\n/ <span class="has-text-red">' +
            (this.stats.exchange_liquid ? this.toUmi(this.stats.exchange_liquid.asks[this.currency], this.currency === "BTC" ? 4 : 2, 1) : 0) + ' ' + this.currency + "</span>"
        },
      ]
    }
  },
  methods: {
    async getData () {
      let result = 0

      await this.$store.dispatch('getStructures')
      await this.$store.dispatch('getGlizeStats')

      const structures = this.structures.filter(s => ['glz', 'gls'].indexOf(s.prefix) > -1)
      for (const structure of structures) {
        result += parseInt(structure.balance)

        if (structure.devAddress) {
          result += parseInt((await axios.get(`${this.api}addresses/${structure.devAddress}/account`)).data.data?.confirmedBalance || 0)
        }
        if (structure.feeAddress) {
          result += parseInt((await axios.get(`${this.api}addresses/${structure.feeAddress}/account`)).data.data?.confirmedBalance || 0)
        }
        if (structure.profitAddress && structure.profitAddress !== structure.feeAddress) {
          result += parseInt((await axios.get(`${this.api}addresses/${structure.profitAddress}/account`)).data.data?.confirmedBalance || 0)
        }
      }

      this.totalBalance = result / 100
    }
  },
  beforeMount () {
    this.getData()
    setInterval(this.getData, 10000)
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #235154 0%, #262B35 100%);
  display: flex;
  align-content: center;
  .logo {
    display: inline-block;
    width: 4rem;
    margin-right: 0.875rem;
  }
  h1 {
    align-self: center;
  }
}

.layout {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  row-gap: 2rem;
  // @media screen and (max-width: 1024px) {
  //   grid-template-columns: repeat(2, minmax(0, 1fr));
  // }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
