<template lang="pug">
div
  .top
    h1.ml-2 ROD&nbsp;
      span(v-translate) Structures
  .body
    template( v-for='structure in structuresSorted' )
      Structure( :structure='structure' )
</template>

<script>
import Structure from '@/components/common/Structure'
import api from '@/api/explorer'

export default {
  components: { Structure },
  data () {
    return {
      structures: []
    }
  },
  computed: {
    structuresSorted () {
      const arr = this.structures
      return arr.sort((a, b) => a.prefix - b.prefix)
    }
  },
  methods: {
    async getStructures () {
      const allStructures = (await api.getStructures()).data.data

      this.structures = allStructures.filter(s => /rod(\d){2}/.test(s.prefix))
    }
  },
  mounted () {
    this.getStructures()
    // api.getStructures().then(console.log)
  }
}
</script>

<style scoped lang="scss">
.top {
  background: linear-gradient(90deg, #262B35, #542323);
  display: flex;
  align-content: center;
  padding-bottom: 7.5rem!important;
  h1 {
    align-self: center;
  }
}
.body {
  margin-top: -5.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4rem;
}
</style>
