<template lang="pug">
  .dashed(:class="{'shorted': label.length > 15}")
    .left {{ item.label }}
    .center
    .right
      a.shorted(v-if="item.link" :href="item.link") {{ label }}
      template(v-else) {{ label }}
</template>

<script>
export default {
  name: 'DashedLine',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    label () {
      return this.item.value + (this.item.suffix ? this.item.suffix : '')
    }
  }
}
</script>

<style scoped lang="scss">
.dashed {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-end;
  .left,
  .center,
  .right {
    // align-content: bottom;
    flex-grow: 0;
    white-space: pre;
  }
  .left {
    flex: 0;
    font-size: 0.825rem;
    color: #ACB1BE;
    vertical-align: bottom;
    
  }
  .center {
    flex-grow: 1;
    margin: 0 0.5rem 0.25rem;
    border-bottom: 1px dashed #3b434f;
    min-width: 1rem;
  }
  .right {
    flex-shrink: 0;
    text-align: right;
    font-family: "PT Mono";
    font-size: 1.125rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  &.shorted {
    .left,
    .right {
      max-width: min-content;
    }
  }
  a {
    color: #17A89B;
    max-width: min-content;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
